import React from "react";

function InputToolTip(props) {
  // onPointerEnter={()=>props.setShowTooltip(props.value)}
  return (
    <>
      {props.showTooltip ? (
        <span className="inputTooltip">{props.name}</span>
      ) : null}
    </>
  );
}

export default InputToolTip;
