import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
const TestCertificateValidation = () => {
  const { state } = useLocation();
  console.log(state);

  // const { dashboard_link } = state;

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      }
    }, 2000);
  }, []);
  const VALIDATIONCertificatesUrl =
    process.env.REACT_APP_CERTIFICATE_PORTAL_VALIDATION_URL;
  return (
    <div className="flex flex-col">
      {isAuthenticated == true ? (
        <div>
          <Header />
          <div className="container-fluid px-0">
            <iframe
              height="100%"
              width="100%"
              src={VALIDATIONCertificatesUrl}
            ></iframe>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default TestCertificateValidation;
