import React, { useState } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";

function ManageSearch(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 px-3 items-end justify-end gap-3 lg:py-5 py-3">
      <div className="lg:block hidden">{/* <p>{props.screenCode}</p> */}</div>
      <div className="flex flex-col col-span-1">
        <div className="manageHeader">Manage {props.name}</div>
      </div>
      <div className="flex lg:flex-row flex-col justify-between items-center col-span-1 lg:gap-7 gap-5">
        <div
          className="flex flex-row items-center justify-center gap-2 relative lg:w-auto w-full"
          onPointerEnter={() => setShowTooltip(true)}
          onPointerLeave={() => setShowTooltip(false)}
        >
          <img
            className="Icons"
            onClick={() => props.OnClickShowDrafts()}
            src={props.show_drafts === false ? UncheckboxIcon : CheckboxIcon}
            alt=""
          />
          {showTooltip ? (
            <span class="text-dark-default bottom-[130%] absolute whitespace-nowrap italic">
              {props.toolTipName}
            </span>
          ) : null}
          <label className="viewDrafts">View Drafts</label>
        </div>
        <div className="searchInput">
          <input
            type="text"
            placeholder={`Search ${props.placeholder}`}
            value={props.search_content}
            onChange={(e) => props.onChangeSearchContent(e)}
            className="border-0 px-1 outline-none rounded w-[96%]"
          />
          <img className="Icons" src={SearchIcon} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ManageSearch;
