import React from "react";

function EditPageButton(props) {
  return (
    <div className="flex flex-row items-center justify-between h-[12%] lg:px-7 md:px-5 px-4 gap-5">
      <button className="cancelButton" onClick={() => props.close_modal(false)}>
        Cancel
      </button>
      <button className="saveButton" onClick={() => props.EditTheItem(false)}>
        Save
      </button>
    </div>
  );
}

export default EditPageButton;
