import React, { useContext, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "../Assert/Close.png";
import axios from "axios";
import { populate_user } from "../Components/Redux/UserSlice";
import { populate_Staff } from "./Redux/StaffSlice";
import { ScreenCodeContext } from "../App";
import { populate_event_notificationLog } from "./Redux/EventNotificationLogSlice";
import { populate_Event_Rules } from "./Redux/EventRulesSlice";
function NotificationWeb() {
  const { isAuthenticated } = useAuth0();
  const showSidebar = useSelector((state) => state.callUser.value);

  const navigate = useNavigate();
  const eventNotificationLog = useSelector(
    (state) => state.callEventNotificationLog.value
  );
  const dispatch = useDispatch();
  const FetchTheEventNotificationLog = () => {
    if (eventNotificationLog.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            let sortSentTimesTamp = temp.sort((a, b) => {
              return (
                new Date(a.sent_timestamp).getTime() -
                new Date(b.sent_timestamp).getTime()
              );
              // if (
              //   new Date(a.sent_timestamp).getTime() >
              //   new Date(b.sent_timestamp).getTime()
              // ) {
              //   return 1;
              // } else if (
              //   new Date(a.sent_timestamp).getTime() <
              //   new Date(b.sent_timestamp).getTime()
              // ) {
              //   return -1;
              // } else {
              //   return 0;
              // }
            });
            dispatch(populate_event_notificationLog(sortSentTimesTamp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let eventRules = useSelector((state) => state.callEventRules.value);

  const FetchTheEventRule = () => {
    if (eventRules.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
        .then((response) => {
          if (response.status == 200) {
            let temp = [];
            for (let i = 0; i < response.data.length; i++) {
              temp.push(response.data[i]);
            }
            dispatch(populate_Event_Rules(temp));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let staff = useSelector((state) => state.callStaff.value);
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }
            dispatch(populate_Staff(tempStaff));
          }
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheEventNotificationLog();
        FetchTheEventRule();
        FetchTheStaff();
      }
    }, 2000);
  }, []);
  // const GetThe24FourData = (eventNotificationLog) => {
  //   let temp = new Date().getTime() - 24 * 60 * 60 * 1000;
  //   let tempFilter = eventNotificationLog.filter(
  //     (e) => new Date(e.sent_timestamp).getTime() >= temp
  //   );
  //   return tempFilter;
  // };
  const ReturnTheHours = (st) => {
    if (new Date(st).getHours() > 12) {
      return "PM";
    } else {
      return "AM";
    }
  };
  const ReturnTheTwelveHoursFormat = (st) => {
    if (new Date(st).getHours() === 0) {
      return 12;
    } else if (new Date(st).getHours() > 12) {
      return Number(new Date(st).getHours() - 12).length === 2
        ? Number(new Date(st).getHours() - 12)
        : String(Number(new Date(st).getHours() - 12)).padStart(2, 0);
    } else {
      return new Date().getHours();
    }
  };
  const ReturnThePaddedMinutes = (st) => {
    return Number(new Date(st).getMinutes()).length === 2
      ? Number(new Date(st).getMinutes())
      : String(Number(new Date(st).getMinutes())).padStart(2, 0);
  };
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  const NotificationsScreenCode = useContext(ScreenCodeContext);

  return (
    <>
      {showSidebar == 1 ? (
        <div className="w-screen h-screen absolute flex flex-col items-end justify-end inset-0">
          <div className="w-screen h-screen absolute bg-black opacity-[0.7] inset-0" />
          <div className="absolute xl:top-[110px] bg-white xl:max-w-xl w-full h-[87%] border border-[#DDE1E6] rounded-md overflow-auto flex p-3  flex-col gap-3">
            <div className="flex flex-row w-full justify-end items-center">
              <img
                onClick={() => dispatch(populate_user(0))}
                className="ProfileIcons"
                src={CloseIcon}
              />
            </div>
            <div className="ReleaseNotesInfo">Notifications</div>
            <div className="notificationsidebar">
              {eventNotificationLog.map((item, index) =>
                new Date().getTime() -
                  new Date(item.sent_timestamp).getTime() <=
                  24 * 60 * 60 * 1000 &&
                staff.find((e) => e.staffDbId == item.staff_db_id) ? (
                  <div
                    className="py-1 px-3 rounded flex flex-col gap-3 bg-[#092E3D] text-white"
                    key={index}
                  >
                    <p>
                      {
                        eventRules.find(
                          (e) => e.event_rule_id == item.event_rule_id
                        ).rule_name
                      }
                    </p>
                    <p>
                      {
                        eventRules.find(
                          (e) => e.event_rule_id == item.event_rule_id
                        ).alert_message
                      }
                    </p>
                    <p className="text-end">
                      {ReturnThePaddedValues(
                        new Date(item.sent_timestamp).getDate()
                      ) +
                        "-" +
                        ReturnThePaddedValues(
                          Number(new Date(item.sent_timestamp).getMonth() + 1)
                        ) +
                        "-" +
                        new Date(item.sent_timestamp).getFullYear() +
                        " " +
                        ReturnTheTwelveHoursFormat(item.sent_timestamp) +
                        ":" +
                        ReturnThePaddedMinutes(item.sent_timestamp) +
                        " " +
                        ReturnTheHours(item.sent_timestamp)}
                    </p>
                  </div>
                ) : null
              )}
            </div>
            <p className="text-center">
              {"Screen ID : " +
                NotificationsScreenCode.find((e) => e.screenId === 157)
                  ?.screenCode}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default NotificationWeb;
