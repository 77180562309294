import React, { useState, useEffect, useRef } from "react";
import UncheckboxIcon from "../Assert/UnCheck.png";
import CheckboxIcon from "../Assert/Checked.png";
import SearchIcon from "../Assert/Search.png";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_Screen } from "./Redux/ScreenSlice";
import AddButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
function Screens() {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "screens?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if(clientSideValidations.length==0){
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidationArray = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidationArray.push(response.data[i]);
          }
          dispatch(
            populate_client_side_validation(tempClientSideValidationArray)
          );
        }
      });
    }
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "screens";

  let screens = useSelector((state) => state.callScreen.value);

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [screensObjectErrors, setScreensObjectErrors] = useState({
    screensNameError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "screensNameError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "screensNameError":
        setScreensObjectErrors((screensObjectErrors) => ({
          ...screensObjectErrors,
          screensNameError: error,
        }));
        break;

      case "effDateFromError":
        setScreensObjectErrors((screensObjectErrors) => ({
          ...screensObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setScreensObjectErrors((screensObjectErrors) => ({
          ...screensObjectErrors,
          effDateToError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = screens.filter((e1) =>
      e1.screenName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (screens.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(screens[i]);
        }
      } else {
        for (let i = 0; i < screens.length; i++) {
          tempArray.push(screens[i]);
        }
      }
      total_pages.current = Math.ceil(
        screens.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [screenArray, setScreenArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [screensObject, setScreensObject] = useState({
    screensName: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const [screensObjectDelete, setScreensObjectDelete] = useState({
    screensName: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  const onResetValue = () => {
    setScreensObject((screensObject) => ({
      ...screensObject,
      screensName: "",
    }));
    setScreensObject((screensObject) => ({
      ...screensObject,
      effDateFrom: new Date(),
    }));
    setScreensObject((screensObject) => ({
      ...screensObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "screensName":
        setScreensObject((screensObject) => ({
          ...screensObject,
          screensName: e.target.value,
        }));
        break;
      case "effDateFrom":
        setScreensObject((screensObject) => ({
          ...screensObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setScreensObject((screensObject) => ({
          ...screensObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          screenArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            screenArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(screenArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            screenArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (screenArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(screenArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(screenArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = screens.findIndex((e) => e.screenId === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(screens[idx]);
    set_item_to_delete(screens[idx].screenId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    console.log("code" + _code);
    console.log("delete_multiple" + multiple_codes);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup("✅Successfully removed the record!!", "success");
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "screens?size=1000",
              null,
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup( error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "screens?size=1000",
                null,
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup( error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      effDateFrom: screensObject.effDateFrom,
      effDateTo:
        status === true ? new Date("1900-01-01") : screensObject.effDateTo,
      screenName: screensObject.screensName,
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };

    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "screens?size=1000",
              null,
              "Post"
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditScreens = () => {
    console.log("Updating" + post_url + "/" + selectpartDbId);
    let deleteData = {
      effDateFrom: GetServerDate(screensObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      screenName: screensObjectDelete.screensName,
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      effDateFrom: GetServerDate(screensObject.effDateFrom),
      effDateTo: GetServerDate(screensObject.effDateTo),
      screenName: screensObject.screensName,
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    axios
      .patch(post_url + "/" + selectpartDbId, deleteData)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(post_url, data)
            .then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON + "screens?size=1000",
                  null,
                  "Patch"
                );
                ShowMEWSPopup("✅Successfully updated the item", "Success");
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message + ":" + post_url + "/" + selectpartDbId);
        console.log(data);
        ShowMEWSPopup( error.message, "Error");
        set_mews_popup_color("#DC3545");
      });
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Screens Map") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "screensName":
            if (
              clientSideValidations[i].validationType === "required" &&
              screensObject.screensName.length == 0
            ) {
              temp++;
              setScreensObjectErrors((screensObjectErrors) => ({
                ...screensObjectErrors,
                customerNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = screens.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        screens.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (screens.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < screens.length; i++) {
          temp_display_array.push(screens[i]);
        }
      } else {
        if (
          screens.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          screens.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < screens.length;
            i++
          ) {
            temp_display_array.push(screens[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_array.push(screens[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    let temp_customerProduct = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];

    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            ShowMEWSPopup("✅Successfully fetched the records", "Success");
            set_mews_popup_color("#28A745");
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_customerProduct.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setScreenArray(tempNonDraftArray);

            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Screen(temp_customerProduct));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          set_draft_array(temp_draft_array);
          setPaginatedArray(temp_display_array);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup( error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };

  const [selectpartDbId, setSelectedPartDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (prodProcessDbId, type) => {
    setSelectedPartDbId(prodProcessDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(prodProcessDbId);
  };
  const SupplyValuesToModal = (screenId) => {
    let dateFrom = screens.find((e) => e.screenId == screenId).effDateFrom;
    let dateTo = screens.find((e) => e.screenId == screenId).effDateTo;

    setScreensObject((screensObject) => ({
      ...screensObject,
      screensName: screens.find((e) => e.screenId == screenId).screenName,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      screensName: screens.find((e) => e.screenId == screenId).screenName,
    }));

    setScreensObject((screensObject) => ({
      ...screensObject,
      effDateFrom: dateFrom,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      effDateFrom: dateFrom,
    }));
    setScreensObject((screensObject) => ({
      ...screensObject,
      effDateTo: dateTo,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      effDateTo: dateTo,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      createdBy: screens.find((e) => e.screenId == screenId).createdBy,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      updatedBy: screens.find((e) => e.screenId == screenId).updatedBy,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      createdTimestamp: screens.find((e) => e.screenId == screenId)
        .createdTimestamp,
    }));
    setScreensObjectDelete((screensObjectDelete) => ({
      ...screensObjectDelete,
      updatedTimestamp: screens.find((e) => e.screenId == screenId)
        .updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortPartMaster = [...screenArray];
    if (sortStyle === "Descending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.screensName;
        const b_res = action === "Code" ? b.partCode : b.screensName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedSupplier);
      setSortStyle("Ascending");
      setScreenArray(updatedSupplier);
    } else if (sortStyle === "Ascending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.screensName;
        const b_res = action === "Code" ? b.partCode : b.screensName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedSupplier);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortPartMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    } else {
      for (let i = 0; i < SortPartMaster.length; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortPartMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setScreenArray(SortPartMaster);
    setPaginatedArray(tempArray);
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <>
            <Header />
            {open_add_modal == false &&
            open_edit_modal == false &&
            open_view_modal == false ? (
              <div className="container-fluid">
                <div
                  className="multipleSelection"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: mews_popup_color,
                      opacity:
                        (open_edit_modal === false &&
                          show_mews_popup === true) ||
                        multiple_codes.length > 0
                          ? 1
                          : 0,
                    }}
                    className="green-bar"
                  >
                    <p>
                      {multiple_codes.length === 0
                        ? "" + mews_message
                        : `You have selected ${multiple_codes.length} records.`}
                      {""}
                      {multiple_codes === 0 ? "Click Here" : null}
                    </p>
                  </div>
                </div>
                <div
                  className="manageSplit"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="2xl:grid-cols-1 xl:grid-cols-1 h-0"></div>
                  <div className="flex flex-col 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1">
                    <div className="manageHeader"> Manage Screens</div>
                  </div>
                  <div className="flex-row items-center justify-center gap-2 2xl:hidden xl:hidden lg:hidden flex">
                    <img
                      className="Icons"
                      onClick={() => OnClickShowDrafts()}
                      src={
                        show_drafts === false ? UncheckboxIcon : CheckboxIcon
                      }
                      alt=""
                    />
                    <label className="viewDrafts">View Drafts</label>
                  </div>
                  <div className="flex flex-row w-full justify-between items-center 2xl:grid-cols-1 xl:grid-cols-1 grid-cols-1 lg:gap-7">
                    <div className="flex-row items-center justify-between gap-2 2xl:flex xl:flex lg:flex hidden">
                      <img
                        className="Icons"
                        onClick={() => OnClickShowDrafts()}
                        src={
                          show_drafts === false ? UncheckboxIcon : CheckboxIcon
                        }
                        alt=""
                      />
                      <label className="viewDrafts">View Drafts</label>
                    </div>
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Screens"
                        value={search_content}
                        onChange={(e) => onChangeSearchContent(e)}
                        className="searchInside"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="tablerounds"
                  style={{
                    opacity:
                      open_view_modal === true ||
                      open_add_modal === true ||
                      open_edit_modal === true ||
                      show_delete_confirmations === true
                        ? 0.25
                        : 4,
                  }}
                >
                  <div className="tableboxes">
                    <table>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>Screen Name</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length == 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.screenId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {item.screenName}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.screenId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.screenId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {item.screenName}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.screenId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.screenId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {item.screenName}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.screenId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.screenId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <div className="anotherIconsSpace">
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "View")
                                      }
                                      src={ViewIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        OpenModal(item.screenId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                                    <img
                                      className="Icons"
                                      onClick={() =>
                                        ShowDeleteConfirmation(item.screenId)
                                      }
                                      src={DeleteIcon}
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <AddButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                  />
                </div>
              </div>
            ) : null}
            {/* //View box */}
            {open_view_modal === true ? (
              <div className="container-fluid">
                <div className="all_popup_box">
                  <div className="green_header_popup">View Screens Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Screens Name</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={screensObject.screensName}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              screensObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  screensObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateFrom).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(screensObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(screensObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateTo).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_view_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="editButton"
                        onClick={() => OpenModal(selectpartDbId, "Edit")}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal === true ? (
              <div className="container-fluid">
                <div className="all_popup_box">
                  <div className="green_header_popup">Edit Screens Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Screen Name</label>
                          <label className="error">
                            {screensObjectErrors.productNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={screensObject.screensName}
                          onChange={(e) =>
                            onChangeValue(e, "screensName", null)
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {screensObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              screensObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  screensObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                       />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {screensObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(screensObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(screensObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                       />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_edit_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => OnEditScreens()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}
            {/* //Add Popup column */}
            {open_add_modal === true ? (
              <div className="container-fluid">
                <div className="all_popup_box">
                  <div className="green_header_popup">Add Screens Details</div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Screen Name</label>
                          <label className="error">
                            {screensObjectErrors.productNameError}
                          </label>
                        </div>
                        <input
                          type="text"
                          value={screensObject.screensName}
                          onChange={(e) =>
                            onChangeValue(e, "screensName", null)
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective From</label>
                          <label className="error">
                            {screensObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(
                              screensObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  screensObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateFrom).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                       />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Effective To</label>
                          <label className="error">
                            {screensObjectErrors.effDateToError}
                          </label>
                        </div>

                        <input
                          type="date"
                          value={
                            new Date(screensObject.effDateTo).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(screensObject.effDateTo).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(screensObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                       />
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="col-span-1 2xl:flex xl:flex lg:flex hidden">
                      <button
                        className="cancelButton"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-wrapFirst">
                      <button
                        className="cancelButton 2xl:hidden xl:hidden lg:hidden flex"
                        onClick={() => set_open_add_modal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="savedraftButton"
                        onClick={() => AddTheItem(true)}
                      >
                        Save as Draft
                      </button>
                      <button
                        className="saveButton"
                        onClick={() => AddTheItem(false)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        {paginatedArray.length === 0 ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Screens;
