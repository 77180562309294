import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { populate_client_side_validation } from "../Components/Redux/ClientSideValidationSlice";
import axios from "axios";

function SuccessMessage(props) {
  useEffect(() => {
    FetchTheValidation();
  }, []);
  const dispatch = useDispatch();
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  return (
    <div className="py-1 px-3">
    {clientSideValidations.length>0?
      <div
        className="flex flex-row items-center rounded-md justify-center text-white h-8 bg-red-800"
        style={{
          backgroundColor: props.mews_popup_color,
          opacity:
            props.show_mews_popup === true || props.multiple_codes.length > 0
              ? 1
              : 0,
        }}
      >
        <p>
          {props.multiple_codes.length === 0
            ? "" + " " + props.mews_message
            : `You have selected ${props.multiple_codes.length} records.`}
          {props.multiple_codes === 0 ? "Click Here" : null}
        </p>
      </div>
      :null
    }
    </div>
  );
}

export default SuccessMessage;
