import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { populate_Staff } from "./Redux/StaffSlice";
import { populate_ContactSupport } from "./Redux/ContactSupportSlice";
import LoadingIcon from "../Assert/Loading.gif";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import { ScreenCodeContext } from "../App";

function ContactSupport() {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheStaff();
        FetchTheContactSupport();
        FetchTheScreen();
        FetchTheScreensToolTips();
      }
    }, 2000);
  }, []);
  const dispatch = useDispatch();
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  let staff = useSelector((state) => state.callStaff.value);
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000")
        .then((response) => {
          if (response.status == 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }
            dispatch(populate_Staff(tempStaff));
          }
        });
    }
  };
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  let contactSupport = useSelector((state) => state.callContactSupport.value);
  const FetchTheContactSupport = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          `contactSupport/staffDbId/${
            staff.findIndex((e) => e.workEmail == user.email) != -1
              ? staff.find((e) => e.workEmail == user.email).staffDbId
              : null
          }`
      )
      .then((response) => {
        if (response.status == 200) {
          let tempContactSupport = [];
          for (let i = 0; i < response.data.length; i++) {
            tempContactSupport.push(response.data[i]);
          }
          dispatch(populate_ContactSupport(tempContactSupport));
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(
          process.env.REACT_APP_BASE_API_PYTHON +
            `contactSupport/${
              staff.findIndex((e) => e.workEmail == user.email) != -1
                ? staff.find((e) => e.workEmail == user.email).staffDbId
                : null
            }`
        );
      });
  };
  const ReturnTheHours = () => {
    if (new Date().getHours() > 12) {
      return "PM";
    } else {
      return "AM";
    }
  };
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  const [ticketMessage, setTicketMessage] = useState("");
  const [ticketMessageError, setTicketMessageError] = useState("");
  const [screenId, setScreenId] = useState(0);
  const onChangeTicketMessage = (e) => {
    setTicketMessage(e.target.value);
  };
  const onChangeScreenId = (e) => {
    setScreenId(e.target.value);
  };
  const Validations = () => {
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Contact Support") {
        csv_count++;
        switch (clientSideValidations[i].fieldName) {
          case "ticketMessage":
            if (
              clientSideValidations[i].validationType === "required" &&
              ticketMessage.length === 0
            ) {
              temp++;
              setTicketMessageError(clientSideValidations[i].shortMessage);
            }
            break;
        }
      }
    }
    return temp;
  };
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "contactSupport";
  const AddTheItem = async () => {
    let data = {
      staffDbId:
        staff.findIndex((e) => e.workEmail == user.email) != -1
          ? staff.find((e) => e.workEmail == user.email).staffDbId
          : null,
      ticketMessage: JSON.stringify([
        { title: screenId, content: ticketMessage },
      ]),
      ticketStatus: "P",
      createdTimestamp:
        new Date().getUTCFullYear() +
        "-" +
        ReturnThePaddedValues(Number(new Date().getUTCMonth()) + 1) +
        "-" +
        ReturnThePaddedValues(new Date().getUTCDate()) +
        " " +
        ReturnThePaddedValues(new Date().getHours()) +
        ":" +
        ReturnThePaddedValues(new Date().getMinutes()) +
        ":" +
        ReturnThePaddedValues(new Date().getSeconds()),
    };
    if (Validations() > 0) {
      return;
    } else {
      try {
        const response = await axios.post(post_url, data);
        console.log(response);
        setTicketMessageError("");
        FetchTheContactSupport();
        Reset();
      } catch (error) {
        console.log(error);
        console.log(data);
      }
    }
  };
  const Reset = () => {
    setTicketMessage("");
    setScreenId(0);
  };
  const [screensArray, setScreensArray] = useState([]);
  async function FetchTheScreen() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_API_PYTHON + "screens"
      );
      setScreensArray(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  const [showTooltip, setShowTooltip] = useState(0);
  const ContactSupportScreenCode = useContext(ScreenCodeContext);

  return (
    <>
      {isAuthenticated === true ? (
        <div className="flex flex-col">
          <Header />
          <div className="container-fluid gap-3">
            <div className="flex flex-row lg:flex-nowrap flex-wrap gap-7 h-full">
              <div className="lg:w-[40%] w-full flex flex-col items-center justify-start gap-5 ">
                <div className="manageHeader">Contact Support</div>
                <div className="parent_popup h-20">
                  <div className="child_label_flex">
                    <label className="child_popup">Screen</label>
                  </div>
                  <InputToolTip
                    showTooltip={showTooltip == 1}
                    name={"Indicates Screen Name"}
                  />
                  <select
                    onPointerEnter={() => setShowTooltip(1)}
                    onPointerLeave={() => setShowTooltip(0)}
                    className="child_input"
                    value={screenId}
                    onChange={(e) => onChangeScreenId(e)}
                  >
                    <option value={null}>Select....</option>
                    {screensArray.filter(e=>e.platform=="Self Service").map((item, index) => (
                      <option key={index} value={item.screenId}>
                        {item.screenName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="parent_popup h-[400px]">
                  <div className="child_label_flex">
                    <label className="child_popup">
                      {
                        screensToolTips.find((e) => e.toolTipDbId == 450)
                          ?.labelName
                      }
                    </label>
                    <label className="error">{ticketMessageError}</label>
                  </div>
                  <InputToolTip
                    showTooltip={showTooltip == 2}
                    name={
                      screensToolTips.find((e) => e.toolTipDbId == 450)
                        ?.toolTipDesc
                    }
                  />
                  <textarea
                    onPointerEnter={() => setShowTooltip(2)}
                    onPointerLeave={() => setShowTooltip(0)}
                    maxLength={500}
                    type="text"
                    className="child_input "
                    value={ticketMessage}
                    onChange={(e) => onChangeTicketMessage(e)}
                  />
                  <div className="flex flex-col items-center justify-center mt-3">
                    <button onClick={() => AddTheItem()} className="saveButton">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col rounded-xl p-3 xl:justify-between lg:justify-between justify-around border-2 self-center gap-3 lg:h-full h-[50%] relative">
                <div className="w-full flex flex-col justify-between self-center gap-3 h-full overflow-auto lg:rounded-t-lg rounded">
                  <table>
                    <colgroup>
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "15%" }} />
                      <col span="1" style={{ width: "25%" }} />
                      <col span="1" style={{ width: "35%" }} />
                      <col span="1" style={{ width: "20%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>Ticket Status</th>
                        <th>Screen Name</th>
                        <th>Ticket Message</th>
                        <th>CreatedTimestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactSupport.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ticketStatus == "P" ? "Pending" :item.ticketStatus == "R " ? "Resolved":item.ticketStatus == "W" ? "Work in Progress":null}</td>
                          <td>
                            {screensArray.findIndex(
                              (e) =>
                                e.screenId ==
                                Number(
                                  JSON.parse(item.ticketMessage)[0]["title"]
                                )
                            ) != -1
                              ? screensArray.find(
                                  (e) =>
                                    e.screenId ==
                                    Number(
                                      JSON.parse(item.ticketMessage)[0]["title"]
                                    )
                                ).screenName
                              : null}
                          </td>
                          <td>
                            {item.ticketMessage != null
                              ? JSON.parse(item.ticketMessage)[0]["content"]
                              : null}
                          </td>
                          <td>
                            {ReturnThePaddedValues(
                              new Date(item.createdTimestamp).getUTCDate()
                            ) +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(item.createdTimestamp).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              new Date(item.createdTimestamp).getUTCFullYear() +
                              " " +
                              ReturnThePaddedValues(
                                new Date(item.createdTimestamp).getUTCHours()
                              ) +
                              ":" +
                              ReturnThePaddedValues(
                                new Date(item.createdTimestamp).getUTCSeconds()
                              ) +
                              " " +
                              ReturnThePaddedValues(
                                new Date(item.createdTimestamp).getUTCMinutes()
                              ) +
                              " " +
                              ReturnTheHours()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {contactSupport.length == 0 && screensArray.length == 0 ? (
                    <img
                      className="LoadingGif left-[50%] absolute top-[50%]"
                      src={LoadingIcon}
                      alt=""
                    />
                  ) : null}
                  <div />
                </div>
                
              </div>
            </div>
            <p className="text-center">
                  {"Screen ID : " +
                    ContactSupportScreenCode.find((e) => e.screenId === 162)
                      ?.screenCode}
                </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ContactSupport;
