import React from "react";

function ViewPageButton(props) {
  return (
    <div className="flex flex-row items-center justify-between h-[12%] lg:px-7 md:px-5 px-4 gap-5">
      <button
        className="cancelButton"
        onClick={() => props.close_modal(false)}
      >
        Cancel
      </button>
      <button className="editButton" onClick={() => props.OpenModal(props.selectedDbId, "Edit")}>
        Edit
      </button>
    </div>
  );
}

export default ViewPageButton;
